<template>
  <div id="mainPage">
    <div class="topBC">
      <!--顶部背景图片-->
      <img src="../static/images/logo_memberShip.png" width="110px" height="45px"/>
    </div>

    <div class="title">
      <span>{{ Title }}</span>
      <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
      <em class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
    </div>

    <!--查询区域-->
    <div class="contextQuery" v-if="pageType == 1">
      <div class="dataInput">
        <div class="inputLine">
          <span class="leftTitle">手机号码</span>
          <input class="middleInput" type="number" oninput="if(value.length > 11)value = value.slice(0, 11)"
                 placeholder="请输入手机号" v-model="form.phone">
        </div>
        <div class="inputLine">
          <span class="leftTitle">图形验证</span>
          <input class="middleInput" type="number" oninput="if(value.length > 4)value = value.slice(0, 4)"
                 placeholder="请输入图形验证码" v-model="form.picValidCode">
          <img id="imgValidcode" class="validCode" :src="valImgSrc" :title="InvisibilityGiveitatry"
               @click="getValImgSrc">
        </div>
        <div class="inputLine">
          <span class="leftTitle">短信验证</span>
          <input class="middleInput" type="text" placeholder="请输入短信验证码" v-model="form.smsValidCode">
          <span class="validButton" @click="getValidCode()">{{ btnText }}</span>
        </div>
      </div>
      <div class="bottom" @click="appointmentQuery()">
        点击查询
      </div>
    </div>

    <div v-if="pageType == 2" style="  margin-top: 50px;padding-bottom: 50px">
      <!--成绩结果-->
      <div class="context" v-for="item in testResults">
        <div v-if="item.Status != 4">
          <!--预约成功-->
          <div class="appointStatus" v-if="item.Status == 1">
            <div class="resultTitle">
              <span style="color: #37CB00; font-size: 28px;text-align: center">预约成功</span>
            </div>
            <div class="contentLine">
              <span>用户姓名:</span>
              <em>{{ item.Name }}</em>
            </div>
            <div class="contentLine">
              <span>预约时间:</span>
              <em>{{ item.CanOrderDate }}</em>
            </div>
            <!--预约商家-->
            <div class="contentLine">
              <span>预约商家:</span>
              <em>{{ item.ShopName }}</em>
            </div>
            <!--商家地址-->
            <div class="contentLine">
              <span>商家地址:</span>
              <em>{{ item.Addr }}</em>
            </div>
            <!--距离测评-->
            <div class="contentTime">
              <div class="timeText">
                <span>距离测评:</span>
                <em>{{ item.dateBet }}</em>
              </div>
              <div :class="[{'disableButton':cancelDisable(item)},{'cancelButton':!cancelDisable(item)}]"
                   @click="cancelAppointment(item)">
                取消预约
              </div>
            </div>
          </div>
          <!--已取消-->
          <div class="appointStatus" v-if="item.Status == 2">
            <div class="resultTitle">
              <span style="color: #FF5800; font-size: 28px;text-align: center">已取消</span>
            </div>
            <div class="contentLine">
              <span>用户姓名:</span>
              <em>{{ item.Name }}</em>
            </div>
            <div class="contentLine">
              <span>预约时间:</span>
              <em>{{ item.CanOrderDate }}</em>
            </div>
            <!--预约商家-->
            <div class="contentLine">
              <span>预约商家:</span>
              <em>{{ item.ShopName }}</em>
            </div>
            <!--商家地址-->
            <div class="contentLine">
              <span>商家地址:</span>
              <em>{{ item.Addr }}</em>
            </div>
          </div>
          <!--预约未到-->
          <div class="appointStatus" v-if="item.Status == 3">
            <div class="resultTitle">
              <span style="color: #FF5800; font-size: 28px;text-align: center">预约未到</span>
            </div>
            <div class="contentLine">
              <span>用户姓名:</span>
              <em>{{ item.Name }}</em>
            </div>
            <div class="contentLine">
              <span>预约时间:</span>
              <em>{{ item.CanOrderDate }}</em>
            </div>
            <!--预约商家-->
            <div class="contentLine">
              <span>预约商家:</span>
              <em>{{ item.ShopName }}</em>
            </div>
            <!--商家地址-->
            <div class="contentLine">
              <span>商家地址:</span>
              <em>{{ item.Addr }}</em>
            </div>
          </div>
        </div>
        <div v-else>
          <!--体测合格-->
          <div class="appointStatus">
            <div class="resultTitle">
                <span v-if="item.IsQualified == 0"
                      style="color: #37CB00; font-size: 28px;text-align: center">评测中</span>
              <span v-if="item.IsQualified == 1"
                    style="color: #37CB00; font-size: 28px;text-align: center">测评结果: A</span>
              <span v-if="item.IsQualified == 2"
                    style="color: #37CB00; font-size: 28px;text-align: center">测评结果: B</span>
              <span v-if="item.IsQualified == 3"
                    style="color: #37CB00; font-size: 28px;text-align: center">测评结果: C</span>
            </div>
            <div class="contentLine">
              <span>用户姓名:</span>
              <em>{{ item.Name }}</em>
            </div>
            <div class="contentLine">
              <span>测试时间:</span>
              <em>{{ filterTime(item.RealTestTime) }}</em>
            </div>
            <!--预约商家-->
            <div class="contentLine">
              <span>预约商家:</span>
              <em>{{ item.ShopName }}</em>
            </div>
            <!--商家地址-->
            <div class="contentLine">
              <span>商家地址:</span>
              <em>{{ item.Addr }}</em>
            </div>
            <div class="resultButton" @click="getTestDetail(item)" v-if="item.IsPublish == 1">
              查看结果
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert ref="alertPart"></alert>
  </div>
</template>

<script>
import {
  GenVerifyPic,
  NotUserGetPhoneVFCode,
  MyOrderFitnessTestListQuery,
  MyOrderFitnessTestListQueryByOpenId,
  MyOrderFitnessTestCancel,
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'

let qs = require('qs');
import Global from '../Global.js'
import Vue from "vue";

export default {
  data() {
    return {
      dateTexttimer: '',
      btnText: '获取验证码',
      Title: '查询测评成绩',
      pageType: 1,//1:查询页面 2:测评成绩结果页面
      getValidSmsState: false,//
      InvisibilityGiveitatry: "无效?再点一下试试吧!",
      valImgSrc: '',
      valImgId: '',
      form: {
        phone: '',
        picValidCode: '',
        smsValidCode: '',
      },
      testResults: [],
    }
  },
  mounted() {
    this.form.phone = this.$route.query.phone;
    if (null != this.$route.query.pageType) {
      this.pageType = this.$route.query.pageType;
      this.appointmentQuery()
    }
    if (localStorage.openId && localStorage.phone) {
      this.getResultByOpenId()
    }
    // 获取图形验证码
    this.getValImgSrc();
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'testMembershipQuery') {
        this.form.phone = this.$route.query.phone;
        if (null != this.$route.query.pageType) {
          this.pageType = this.$route.query.pageType;
          this.appointmentQuery();
        }
        if (localStorage.openId && localStorage.phone) {
          this.getResultByOpenId()
        }
        // 获取图形验证码
        this.getValImgSrc();
      }
    },
    'active'() {
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    // 点击验证码切换
    getValImgSrc() {
      let that = this;
      let param = {
        token: localStorage.token,
        width: 100,
        height: 30,
        noiseCount: 1,
        length: 4,
        source: 123456789,
      };
      let postdata = qs.stringify(param);
      GenVerifyPic(postdata).then(res => {
        let json = res;
        that.valImgSrc = json.Pic;
        that.valImgId = json.Id;
      })
    },
    getValidCode() {
      let that = this;
      let phone = that.form.phone;
      let uservalid = that.form.picValidCode;
      if (!phone) {
        that.Toast('手机号不能为空');
        return false
      }
      if (phone.length != 11) {
        that.Toast('手机号只能是11位');
        return false
      }
      if (!globalCheckPhone(phone)) {
        that.Toast('手机号格式不正确');
        return false
      }
      if (!uservalid) {
        that.Toast('图形验证码不能为空');
        return false
      }
      if (uservalid.length != 4) {
        that.Toast('图形验证码只能是4位');
        return false
      }
      let param = {
        token: localStorage.token,
        phone: phone,
        codeType: 2,
        picId: this.valImgId,
        picCode: uservalid,
        oType: 2
      };
      let postdata = qs.stringify(param);
      NotUserGetPhoneVFCode(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.Toast('短信验证码已发送', 'success');
          that.calcTime();
        } else {
          that.Toast(json.Memo);
          if (json.Code == 1003) {
            this.getValImgSrc();
            that.form.picValidCode = '';
          }
        }
      })
    },
    calcTime() {
      // 短信倒数计时
      let countdown = 60;
      let that = this;
      //设置button效果，开始计时
      that.getValidSmsState = true;
      that.btnText = countdown + "S"
      //启动计时器，1秒执行一次
      let timer = setInterval(function () {
        if (countdown == 0) {
          clearInterval(timer);//停止计时器
          that.getValidSmsState = false;
          that.btnText = "重新发送"
        } else {
          countdown--;
          that.btnText = countdown + "S"
        }
      }, 1000);
    },
    appointmentQuery() {
      let that = this;
      //常规手机号方式查询（附带绑定openId）
      if (that.pageType == 1) {
        this.getResultBySms();
      }
      if (that.pageType == 2) {
        this.getResultByOpenId()
      }
    },
    getResultBySms() {
      let that = this;
      let param = {
        phone: that.form.phone,
        code: that.form.smsValidCode,
        openId: localStorage.openId ? localStorage.openId : ''
      };
      MyOrderFitnessTestListQuery(qs.stringify(param)).then(res => {
        let json = res;
        if (json.Code == 0) {
          localStorage.phone = that.form.phone;
          if (json.Rs.length > 0) {
            that.testResults = json.Rs;
            that.pageType = 2;//切换到结果页
            that.addDateBetween();
          } else {
            that.testResults = [];
            that.alertInfo('未查到记录');
          }
        } else {
          that.alertInfo(json.Memo);
        }
      })
    },
    getResultByOpenId() {
      let that = this;
      let param = {
        openId: localStorage.openId
      };
      MyOrderFitnessTestListQueryByOpenId(qs.stringify(param)).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.Rs.length > 0) {
            that.testResults = json.Rs;
            that.pageType = 2;//切换到结果页
            that.addDateBetween();
          } else {
            that.testResults = [];
            that.alertInfo('未查到记录');
          }
        } else {
          that.$router.push({path: '/testMembershipQuery'});
          that.alertInfo(json.Memo);
        }
      })
    },
    //取消预约
    cancelAppointment(item) {
      let that = this;
      if (that.cancelDisable(item)) {
        return;
      }
      this.$confirm('确定要取消预约吗？', '提示', {
        type: 'warning'
      }).then(({result}) => {
        if (result) {
          let param = {
            oftId: item.OftId,
            phone: item.Phone
          };
          let postdata = qs.stringify(param);
          MyOrderFitnessTestCancel(postdata).then(res => {
            let json = res;
            if (json.Code == 0) {
              that.appointmentQuery()
            } else {
              that.alertInfo(json.Memo);
            }
          })
        }
      });
    },
    addDateBetween() {
      let that = this;
      for (let i in that.testResults) {
        that.testResults[i].dateBet = that.getTimeBetweenStr(that.testResults[i].CanOrderDate);
      }
    },
    cancelDisable(item) {
      let that = this;
      let disable = that.getTimeDiffGetArr(item.CanOrderDate)[0] < 1 && that.getTimeDiffGetArr(item.CanOrderDate)[1] < 1 && that.getTimeDiffGetArr(item.CanOrderDate)[2] < 61;
      return disable;
    },
    getTimeDiffGetArr(endDate) {
      return timeDiffGetArr(timeNow(), endDate);
    },
    getTimeBetweenStr(endDate) {
      let diffArr = timeDiffGetArr(timeNow(), endDate);
      let diffStr = '';
      if (diffArr[0] > 0) {
        diffStr += diffArr[0] + ' 天 ';
      }
      if (diffArr[1] > 0) {
        diffStr += diffArr[1] + ' 小时 ';
      }
      if (diffArr[2] > 0) {
        diffStr += diffArr[2] + ' 分 ';
      }
      if (diffStr == '') {
        diffStr = '0 分钟';
      }
      return diffStr;
    },
    startTimer() {
      let that = this;
      that.dateTexttimer = setInterval(function () {
        if (that.testResults) {
          for (let i in that.testResults) {
            that.testResults[i].dateBet = that.getTimeBetweenStr(that.testResults[i].CanOrderDate);
          }
          console.log(that.testResults);
        }
      }, 1000 * 20);//启动计时器，60秒执行一次
    },
    getTestDetail(row) {
      this.$router.push({
        path: '/testMembershipDetail', query: {OftId: row.OftId}
      });
    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
    goPage(pages) {
      this.$router.push({path: pages});
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.dateTexttimer);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
      }
    });
  }
  ,
  components: {
    bottomTab, alert
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: url('../static/images/bc_memberShip.png') top center / cover;
  overflow-y: scroll;
}

.topBC {
  height: 80px;
  width: 100%;
  text-align: center;
}

.topBC img {
  margin-top: 21px;
}

.title {
  height: 35px;
  width: 100%;
  margin-top: 10px;
}

.contextQuery {
  width: 90%;
  margin-left: 5%;
  padding-top: 15px;
  backdrop-filter: blur(5px);
  margin-top: 50px;
  background: rgba(255, 255, 255, 0.66);
  border-radius: 10px;
  padding-bottom: 30px;
}

.context {
  width: 90%;
  margin-left: 5%;
  margin-top: 30px;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

.title img {
  float: right;
  margin-top: -55px;
  margin-right: 15px;
}

.title span {
  display: block;
  font-size: 28px;
  text-align: center;
  color: white;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}

.dataInput .inputLine {
  height: 40px;
  width: 90%;
  margin-left: 5%;
  background: none;
  margin-top: 5px;
  display: flex;
}

.inputLine .leftTitle {
  width: 105px;
  font-weight: 500;
  display: block;
  margin-left: 1%;
  text-align: left;
  color: #3B3B3B;
  font-size: 16px;
  margin-right: 18px;
  line-height: 60px;
}

.inputLine .middleInput {
  width: 44%;
  background: none;
  margin-left: -55px;
  margin-top: 15px;
  color: #515151;
  border: none;
  outline: medium;
  height: 29px;
  text-indent: 10px;
}

.inputLine .validCode {
  width: 85px;
  margin-top: 15px;
  margin-left: 2px;
  height: 25px;
  text-indent: 10px;
  border: #E3E3E3 1px solid;
}


.inputLine em {
  width: 20px;
  line-height: 58px;
  margin-left: 10px;
}

.inputLine .validButton {
  width: 85px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin-top: 17px;
  margin-left: 3px;
  border-radius: 2px;
  color: #ffffff;
  background: #FF6E14;
  text-align: center;
}

.bottom {
  height: 45px;
  background: #33CAF7;
  width: 75%;
  font-size: 18px;
  color: #FFFFFF;
  border-radius: 24px;
  text-align: center;
  line-height: 45px;
  margin: 40px auto 0;
}

/*成绩结果*/
.title span {
  display: block;
  font-size: 36px;
  color: white;
  text-align: center;
}

.context .appointStatus {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
  padding: 5px 39px 30px;
  border-radius: 15px;
}

.appointStatus .resultTitle {
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  color: #37CB00;
  text-align: center;
}

.appointStatus .contentLine {
  margin-top: 3px;
}

.appointStatus .contentLine span {
  font-size: 14px;
}

.appointStatus .contentLine em {
  font-size: 14px;
  margin-left: 12px;
}

.appointStatus .resultLine em {
  font-size: 14px;
  margin-left: 40px;
}

.appointStatus .contentTime {
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}

.appointStatus .contentTime .timeText {
  float: left;
  margin-right: 5px;
  font-size: 14px;
}

.appointStatus .contentTime .timeText span {
  margin-right: 12px;
}

.appointStatus .contentTime .cancelButton {
  float: left;
  font-size: 14px;
  margin-left: 5px;
  color: #FF5800;
}

.appointStatus .disableButton {
  float: left;
  font-size: 14px;
  margin-left: 5px;
  color: #CECECE;
}

.appointStatus .cancelButton {
  float: right;
  font-size: 14px;
  color: #FF5800;
}

.appointStatus .resultButton {
  font-size: 14px;
  color: #ffffff;
  background: #37CB00;
  border-radius: 4px;
  width: 105px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 30px auto 0;
}

.appointStatus .disableButton {
  font-size: 14px;
  color: #CECECE;
}


.attentionContent span {
  whiteSpace: 'pre-line';
}


/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
